export const HOME = '/';
export const SHOP = "/Shop";
export const JOBS = "/Jobs";
export const PRODUCTS = "/Products";
export const SERVICES = "/Services";
export const HOME_USER = "/homeUser";
export const NEW = "/New";
export const S_Liive = "/S_Liive";
export const REMOTE_API =
  "https://api-publica.onrender.com/server/liive/products/imgs";
export const LOCAL_API = "http://localhost:2025/server/liive/products/imgs";
export const THIS_PERFIL = "http://localhost:3000/HomeUser";
export const EQUIPMENTS = "/Equipments";
export const MARKETPLACE = "/Marketplace";
export const FEATURED_PRODUCTS = "/Shop/featured_products";
export const BLACK_DAYS = "/BlackDays";
export const SENDGIFT = "/SenGift";
export const SERVICES_DESING = "/Services/desing";
export const SERVICES_DEV = "/Services/dev";
export const SERVICES_DEV_DESING = "/Services/dev/desing";
export const SERVICES_DEV_WEB = "/Services/dev/web";
export const SERVICES_DEV_MOBILE = "/Services/dev/mobile";
export const SERVICES_HVAC = "/Services/hvac";
export const SERVICES_Delivery = "/Services/Delivery";
export const RECOMMENDED_PRODUCTS = '/recommended';
export const ACCOUNT = '/account';
export const ACCOUNT_EDIT = '/account/edit';
export const ADMIN_DASHBOARD = '/admin/dashboard';
export const ADMIN_PRODUCTS = '/admin/products';
export const ADMIN_USERS = '/admin/users';
export const ADD_PRODUCT = '/admin/add';
export const EDIT_PRODUCT = '/admin/edit';
export const SEARCH = '/search/:searchKey';
export const SIGNIN = '/signin';
export const SIGNAUTH = "/signAuth";
export const FORGOT_PASSWORD = '/forgot_password';
export const CHECKOUT_STEP_1 = '/checkout/step1';
export const CHECKOUT_STEP_2 = '/checkout/step2';
export const CHECKOUT_STEP_3 = '/checkout/step3';
export const VIEW_PRODUCT = '/product/:id';
